import type { FormInput } from '@/utils/client/forms/intervention/edit'
import type {
  Intervention,
  PaymentMethod,
} from '@laast-io/types'

import { addMinutes, roundToNearestMinutes } from 'date-fns'
import type { FetchInterventionsKanban } from '~/composables/data/interventions/kanban'
import type { FetchInterventionPayment } from '~/composables/data/modals/intervention-payment'
import type { FetchIntervention } from '~/composables/data/panels/interventions'
import {
  sendCreatedNotification,
  sendPayedNotification,
  sendStatusChangeNotification,
} from './notifications'

export async function createIntervention({
  formValues,
}: {
  formValues: FormInput
}) {
  const $directus = useDirectusSdk()

  // create or select customer device
  let customerDevice: any = null
  if (formValues?.customer?.id && formValues.device_model?.id) {
    // use selected device
    customerDevice = formValues?.customer_device?.id
      ? {
          id: formValues?.customer_device?.id,
          serial_number: formValues?.customer_device?.serial_number,
        }
      : {
          serial_number: formValues?.customer_device?.serial_number,
          customer: formValues?.customer?.id,
          device_model: formValues.device_model?.id,
        }
  }

  const cartItems = [] as {
    item: string
    quantity: number
    discount: number | null
    discount_type: string | null
    discount_label: string | null
    discount_id: string | null
    stock_deducted: number
    status: string | null | undefined
  }[]

  for (const [, item] of Object.entries(formValues.items ?? [])) {
    // skip last empty service
    if (!item?.item?.id) {
      continue
    }
    if (!item.quantity) {
      continue
    }

    let deducted = item.quantity

    if (item.item.has_managed_stocks) {
      deducted = Math.min(item.item.in_stock ?? 0, item.quantity)
    }

    cartItems.push({
      item: item.item.id,
      quantity: item.quantity,
      discount: item.discount ?? null,
      discount_type: item.discount_type ?? null,
      discount_label: item.discount_label ?? null,
      discount_id: item.discount_id ?? null,
      stock_deducted: deducted,
      // in_stock: item.item.in_stock ?? 0,
      // has_managed_stocks: item.item.has_managed_stocks ?? false,
      status: formValues.status,
    })
  }

  const plannedServices = [] as {
    service: string
    order: number | string
    discount: number | null
    discount_type: string | null
    discount_label: string | null
    discount_id: string | null
    require_spare_part: {
      id: number | undefined | null
      quantity: number | undefined | null
      stock_deducted: number | undefined | null
      price_modifier: number | undefined | null
      spare_part: string | undefined
      status: string | undefined | null
    }[]
    status: string | undefined | null
    custom_status: string | undefined | null
  }[]

  for (const [idx, planned] of Object.entries(formValues.planned_services ?? [])) {
    // skip last empty service
    if (!planned?.service?.id) {
      continue
    }

    plannedServices.push({
      service: planned.service.id,
      order: idx,
      discount: planned.discount ?? null,
      discount_type: planned.discount_type ?? null,
      discount_label: planned.discount_label ?? null,
      discount_id: planned.discount_id ?? null,
      require_spare_part: (planned.require_spare_part ?? []).map(part => ({
        id: part.id,
        quantity: part.quantity,
        stock_deducted: part.stock_deducted,
        price_modifier: part.price_modifier,
        spare_part: part.spare_part?.id,
        status: formValues.status,
      })),
      status: formValues.status,
      custom_status: planned.custom_status,
    })
  }

  // create intervention
  const intervention = await $directus.request(
    createItem('intervention', {
      status: formValues.status,
      customer_device: customerDevice,
      device_model: formValues?.device_model?.id || null,
      planned_services: plannedServices as any[],
      items: cartItems as any[],
      customer: formValues?.customer?.id || null,
      estimated_time: formValues?.estimated_time,
      date_status_updated: new Date().toISOString(),
      date_scheduled: formValues.date_scheduled?.toISOString(),
      date_estimated: formValues.date_estimated?.toISOString(),
      comment: formValues?.comment,
      comment_invoice: formValues?.comment_invoice,
      comment_hold: formValues?.comment_hold,
      on_hold: formValues?.on_hold,
    }, {
      fields: ['*'],
    }),
  )

  if (intervention?.id) {
    await sendCreatedNotification({
      intervention,
      formValues,
    })
  }

  return intervention
}

export async function updateIntervention({
  intervention,
  formValues,
}: {
  intervention: FetchIntervention
  formValues: FormInput
}) {
  const $directus = useDirectusSdk()

  if (!formValues.id)
    return

  // create or select customer device
  let customerDevice: any = null
  if (formValues?.customer?.id && formValues.device_model?.id) {
    // use selected device
    customerDevice = formValues?.customer_device?.id
      ? {
          id: formValues?.customer_device?.id,
          serial_number: formValues?.customer_device?.serial_number,
        }
      : {
          serial_number: formValues?.customer_device?.serial_number,
          customer: formValues?.customer?.id,
          device_model: formValues.device_model?.id,
        }
  }

  const cartItems = [] as {
    id?: number | null
    item: string
    quantity: number
    discount: number | null
    discount_type: string | null
    discount_label: string | null
    discount_id: string | null
    stock_deducted: number
    status?: string | null
  }[]

  for (const [, item] of Object.entries(formValues.items ?? [])) {
    // skip last empty service
    if (!item?.item?.id) {
      continue
    }
    if (!item.quantity) {
      continue
    }

    let deducted = item.quantity

    if (item.item.has_managed_stocks) {
      deducted = Math.min(item.item.in_stock ?? 0, item.quantity)
    }

    cartItems.push({
      id: item?.id,
      item: item.item.id,
      quantity: item.quantity,
      discount: item.discount ?? null,
      discount_type: item.discount_type ?? null,
      discount_label: item.discount_label ?? null,
      discount_id: item.discount_id ?? null,
      stock_deducted: deducted,
      status: formValues.status,
    })
  }

  const plannedServices = [] as {
    id: number | undefined | null
    service: string
    order: number | string
    discount: number | null
    discount_type: string | null
    discount_label: string | null
    discount_id: string | null
    require_spare_part: {
      id: number | undefined | null
      quantity: number | undefined | null
      stock_deducted: number | undefined | null
      spare_part: string | undefined
      status: string | undefined | null
      price_modifier: number | undefined | null
    }[]
    status: string | undefined | null
    custom_status: string | undefined | null
  }[]

  for (const [idx, planned] of Object.entries(formValues.planned_services ?? [])) {
    // skip last empty service
    if (!planned || !planned?.service?.id) {
      continue
    }

    plannedServices.push({
      id: planned?.id,
      service: planned.service.id,
      order: idx,
      discount: planned.discount ?? null,
      discount_type: planned.discount_type ?? null,
      discount_label: planned.discount_label ?? null,
      discount_id: planned.discount_id ?? null,
      require_spare_part: (planned.require_spare_part ?? []).map(part => ({
        id: part.id,
        quantity: part.quantity,
        price_modifier: part.price_modifier,
        stock_deducted: part.stock_deducted,
        spare_part: part.spare_part?.id,
        status: formValues.status,
      })),
      status: formValues.status,
      custom_status: planned.custom_status,
    })
  }

  const updated = await $directus.request(
    updateItem('intervention', formValues.id, {
      status: formValues.status,
      customer_device: customerDevice,
      device_model: formValues?.device_model?.id || null,
      planned_services: plannedServices as any[],
      items: cartItems as any[],
      customer: formValues?.customer?.id || null,
      estimated_time: formValues?.estimated_time,
      date_status_updated: formValues.date_status_updated?.toISOString(),
      date_scheduled: formValues.date_scheduled?.toISOString() || null,
      date_estimated: formValues.date_estimated?.toISOString() || null,
      comment: formValues?.comment,
      comment_invoice: formValues?.comment_invoice,
      comment_hold: formValues?.comment_hold,
      on_hold: formValues?.on_hold,
    }),
  )

  if (formValues.status && intervention.status !== formValues.status) {
    await sendStatusChangeNotification({
      intervention,
      status: formValues.status,
    })
  }

  return updated
}

export async function updateInterventionStatus({
  intervention,
  status,
}: {
  intervention: Intervention | FetchInterventionsKanban[number]
  status: string
}) {
  const $directus = useDirectusSdk()

  if (status === 'payed') {
    // Changement to payed status requires to pass through the payment process
    return
  }

  if (status === 'archived') {
    throw new Error(`Cannot update status to archived`)
  }

  // status update
  const data = {
    status,
    date_status_updated: new Date().toISOString(),
    date_started: undefined as string | undefined,
    date_scheduled: undefined as string | undefined,
    date_estimated: undefined as string | undefined,
    date_completed: undefined as string | undefined,
  } as any

  // reset planned services status if needed
  // const updatePlannedServicesId = []

  const isFromRequireRestocking = ['require_restocking', 'pending_restocking'].includes(intervention.status ?? '')
  const isToRequireRestocking = ['require_restocking', 'pending_restocking'].includes(status)

  const plannedData = {} as Record<string, {
    id: number
    custom_status: string
    require_spare_part?: {
      id: number
      stock_deducted: number
    }[]
  }>

  if (Array.isArray(intervention.planned_services)) {
    for (const planned of intervention.planned_services) {
      if (planned.id) {
        plannedData[planned.id] = {
          id: planned.id,
          custom_status: status,
        }
      }
    }
  }

  if (status === 'started' && !intervention.date_started) {
    const now = roundToNearestMinutes(new Date(), {
      nearestTo: 5,
    })
    data.date_started = now.toISOString()

    // only set date scheduled if there is an estimated time
    // (otherwise it only contains item in cart)
    if (intervention.estimated_time) {
      data.date_scheduled ??= now.toISOString()
      data.date_estimated ??= roundToNearestMinutes(
        addMinutes(now, intervention?.estimated_time),
        { nearestTo: 15 },
      ).toISOString()
    }
  }
  else if (status === 'complete') {
    data.date_completed = new Date().toISOString()
  }

  if (isFromRequireRestocking && !isToRequireRestocking) {
    // mark as deducted
    if (Array.isArray(intervention.planned_services)) {
      for (const planned of intervention.planned_services) {
        if (planned.id) {
          plannedData[planned.id] = {
            id: planned.id,
            custom_status: status,
            require_spare_part: (planned.require_spare_part ?? []).map((part: any) => ({
              id: part.id,
              stock_deducted: part.quantity,
            })),
          }
        }
      }
    }
  }
  else if (!isFromRequireRestocking && isToRequireRestocking) {
    // mark as require restocking
    if (Array.isArray(intervention.planned_services)) {
      for (const planned of intervention.planned_services) {
        if (planned.id) {
          plannedData[planned.id] = {
            id: planned.id,
            custom_status: status,
            require_spare_part: (planned.require_spare_part ?? []).map((part: any) => ({
              id: part.id,
              stock_deducted: 0,
            })),
          }
        }
      }
    }
  }

  if (Object.values(plannedData).length) {
    data.planned_services = Object.values(plannedData)
  }

  await $directus.request(updateItem('intervention', intervention.id, data))
  await sendStatusChangeNotification({
    intervention,
    status,
  })
}

export async function validatePayment({
  intervention,
  transactions,
  // selectedPaymentMethod,
}: {
  intervention: FetchInterventionPayment
  transactions: {
    type: 'bank' | 'user_credit'
    payment_method: PaymentMethod | null
    amount: number
  }[]
}) {
  if (!intervention) {
    throw new Error('Invalid intervention or payment method')
  }
  const $directus = useDirectusSdk()
  const deviceCategory = useDeviceCategory()

  const invoiceDetails = computeInvoiceData(
    intervention,
    deviceCategory
      .getBreadcrumb(intervention?.device_model?.device_category)
      .map(category => category.name)
      .join(' / '),
  )

  // status update
  // const tasks: Promise<any>[] = []
  const date = new Date().toISOString()
  const interventionData = {
    status: 'payed',
    on_hold: false,
    date_payed: date,
    date_status_updated: date,
  } as Record<string, any>

  const transactionsPrepayment = (intervention.transactions?.filter(transaction => !transaction.invoice) ?? []).map(transaction => ({
    id: transaction.id,
  }))
  const transactionsNew = transactions.map(transaction => ({
    intervention: intervention.id,
    payment_method: transaction.payment_method?.id ?? null,
    amount: transaction.amount,
    type: transaction.type,
    customer: intervention.customer?.id,
  }))
  const userCredit = transactionsNew?.find(transaction => transaction.type === 'user_credit')

  if (
    userCredit && !intervention.customer
  ) {
    throw new Error('No customer found for user credit')
  }

  if (userCredit && intervention.customer && userCredit.amount > (intervention.customer?.credit ?? 0)) {
    throw new Error('Customer credit is not enough')
  }

  const invoiceData = {
    ...invoiceDetails,
    type: 'invoice',
    date_payed: date,
    transactions: [...transactionsPrepayment, ...transactionsNew],
    // payment_method: selectedPaymentMethod.label ?? '',
    // payment_method_id: selectedPaymentMethod.id!,
  }

  // mark as deducted
  const plannedData = {} as Record<string, {
    id: number
    status: string
    custom_status: string
    require_spare_part?: {
      id: number
      status: string
      stock_deducted: number
    }[]
  }>
  const itemsData = {} as Record<string, {
    id: number
    status: string
    stock_deducted: number
  }>

  if (Array.isArray(intervention.planned_services)) {
    for (const planned of intervention.planned_services) {
      if (planned.id) {
        plannedData[planned.id] = {
          id: planned.id,
          status: 'payed',
          custom_status: 'payed',
          require_spare_part: (planned.require_spare_part as any ?? []).map((part: any) => ({
            id: part.id,
            status: 'payed',
            stock_deducted: part.quantity,
          })),
        }
      }
    }
  }
  if (Array.isArray(intervention.items)) {
    for (const item of intervention.items) {
      if (item.id) {
        itemsData[item.id] = {
          id: item.id,
          status: 'payed',
          stock_deducted: item.quantity,
        }
      }
    }
  }

  // stock management
  const partStocks = {} as Record<string, number>
  const itemStocks = {} as Record<string, number>
  if (Array.isArray(intervention.planned_services)) {
    for (const planned of intervention.planned_services) {
      if (Array.isArray(planned.require_spare_part)) {
        for (const part of planned.require_spare_part) {
          if (typeof part.spare_part === 'object' && part.spare_part && 'id' in part.spare_part && part.quantity) {
            if (!part.spare_part.has_managed_stocks) {
              continue
            }
            partStocks[part.spare_part.id] ??= part.spare_part.in_stock
            partStocks[part.spare_part.id] -= part.quantity
          }
        }
      }
    }
  }

  if (Array.isArray(intervention.items)) {
    for (const item of intervention.items) {
      if (typeof item.item === 'object' && item.item && 'id' in item.item && item.quantity) {
        if (!item.item.has_managed_stocks) {
          continue
        }
        itemStocks[item.item.id] ??= item.item.in_stock
        itemStocks[item.item.id] -= item.quantity
      }
    }
  }

  for (const [id, quantity] of Object.entries(itemStocks)) {
    await $directus.request(
      updateItem('item', id, {
        in_stock: Math.max(0, quantity),
      }),
    )
  }
  for (const [id, quantity] of Object.entries(partStocks)) {
    await $directus.request(
      updateItem('spare_part', id, {
        in_stock: Math.max(0, quantity),
      }),
    )
  }

  if (userCredit?.amount && intervention?.customer?.id) {
    await $directus.request(
      updateItem('customer', intervention.customer?.id, {
        credit: Math.max(0, substractPrices(intervention.customer?.credit ?? 0, userCredit.amount)),
      }),
    )
  }

  if (Object.values(plannedData).length) {
    interventionData.planned_services = Object.values(plannedData)
  }
  if (Object.values(itemsData).length) {
    interventionData.items = Object.values(itemsData)
  }

  await $directus.request(
    updateItem('intervention', intervention?.id, interventionData),
  )

  const invoice = await $directus.request(createItem('invoice', invoiceData, {
    fields: [
      '*',
    ],
  }))

  if (!invoice) {
    throw new Error('Invoice not created')
  }

  // send "payed" notification
  await sendPayedNotification({
    intervention,
    invoice,
  })

  return invoice
}
