import type { FormInput } from '@/utils/client/forms/intervention/edit'
import type {
  Customer,
  Intervention,
  Invoice,
} from '@laast-io/types'

import { joinURL } from 'ufo'
import type { FetchInterventionsKanban } from '~/composables/data/interventions/kanban'
import type { FetchInterventionPayment } from '~/composables/data/modals/intervention-payment'
import type { FetchIntervention } from '~/composables/data/panels/interventions'

export async function sendStatusChangeNotification({
  intervention,
  status,
}: {
  intervention: Intervention | FetchInterventionsKanban[number] | FetchIntervention
  status: string
}) {
  const customer = intervention.customer as Customer | undefined

  if (!customer?.id) {
    return
  }

  const $directus = useDirectusSdk()
  const settings = useShopSettings()

  let deviceName = 'appareil'

  // get device name
  const { getById } = useDeviceCategory()

  if (intervention.device_model && typeof intervention.device_model === 'object') {
    if (intervention.device_model.device_category && typeof intervention.device_model.device_category === 'object') {
      deviceName = `${intervention.device_model.device_category.name} ${intervention.device_model.name}`
    }
    else if (
      intervention.device_model.device_category
      && typeof intervention.device_model.device_category === 'string'
      && getById(intervention.device_model.device_category)
    ) {
      const category = getById(intervention.device_model.device_category)
      deviceName = `${category!.name} ${intervention.device_model.name}`
    }
    else {
      deviceName = intervention.device_model.name
    }
  }

  let send = false
  switch (status) {
    case 'started': {
      if (intervention.date_started) {
        // send "restarted" notification
        await $directus.request(
          createItem('customer_message', {
            from: 'system',
            is_unread: false,
            customer: customer.id,
            intervention: intervention.id,
            message: [
              `${capitalize(
                customer?.first_name,
              )}, la réparation de votre ${deviceName} vient de reprendre son cours.`,
              '',
              `Elle devrait durer environ ${formatMinutes(
                intervention?.estimated_time,
              )} mais nous vous avertirons une dernière fois dès qu'elle sera effectivement terminée.`,
              `Ce sera donc l'heure de vous restituer l'appareil !`,
              ``,
              `N'hésitez pas à répondre à ce message pour toutes questions.`,
              ``,
              ...(settings.data?.name
                ? [`Votre réparateur,`, `${settings.data?.name}`]
                : [`Votre réparateur`]),
            ].join('\n'),
          }),
        )
        send = true
      }
      else {
        // send "started" notification
        await $directus.request(
          createItem('customer_message', {
            from: 'system',
            is_unread: false,
            customer: customer.id,
            intervention: intervention.id,
            message: [
              `${capitalize(
                customer?.first_name,
              )}, la réparation de votre ${deviceName} vient de débuter.`,
              '',
              `Elle devrait durer environ ${formatMinutes(
                intervention?.estimated_time,
                'quleques minutes',
              )} mais nous vous avertirons une dernière fois dès qu'elle sera effectivement terminée.`,
              `Ce sera donc l'heure de vous restituer l'appareil !`,
              ``,
              `N'hésitez pas à répondre à ce message pour toutes questions.`,
              ``,
              ...(settings.data?.name
                ? [`Votre réparateur,`, `${settings.data?.name}`]
                : [`Votre réparateur`]),
            ].join('\n'),
          }),
        )
        send = true
      }
      break
    }
    case 'require_restocking': {
      await $directus.request(
        createItem('customer_message', {
          from: 'system',
          is_unread: false,
          customer: customer.id,
          intervention: intervention.id,
          message: [
            `Chèr.e ${capitalize(customer?.first_name)}, la réparation de votre ${deviceName} nécessite la commande d'une pièce détachée.`,
            '',
            `Nous poursuivrons l'intervention dès réception de la marchandise (généralement dans les 2 jours ouvrés).`,
            `On vous tient informé.e de la suite très vite !`,
            ``,
            `N'hésitez pas à répondre à ce message pour toutes questions.`,
            ``,
            ...(settings.data?.name
              ? [`Votre réparateur,`, `${settings.data?.name}`]
              : [`Votre réparateur`]),
          ].join('\n'),
        }),
      )
      send = true
      break
    }
    case 'complete': {
      // send "completed" notification
      await $directus.request(
        createItem('customer_message', {
          from: 'system',
          is_unread: false,
          customer: customer.id,
          intervention: intervention.id,
          message: [
            `Bonjour${customer?.first_name ? ` ${capitalize(customer?.first_name)}` : ''},`,
            ``,
            `L’intervention effectuée sur votre ${deviceName} est terminée.`,
            `Vous pouvez récupérer l’appareil dès maintenant.`,
            ``,
            `N'hésitez pas à répondre à ce message pour toutes questions.`,
            ``,
            ...(settings.data?.name
              ? [`Votre réparateur,`, `${settings.data?.name}`]
              : [`Votre réparateur`]),
          ].join('\n'),
        }),
      )

      send = true
      break
    }
  }

  // update customer messages status
  if (send && customer?.messages_status !== 'active') {
    await $directus.request(
      updateItem('customer', customer.id, {
        messages_status: 'active',
      }),
    )
  }
}

export async function sendPayedNotification({
  intervention,
  invoice,
}: {
  intervention: FetchInterventionPayment
  invoice: Invoice
}) {
  const customer = intervention.customer as Customer | undefined

  if (!customer?.id) {
    return
  }

  const nuxtBaseUrl = useRuntimeConfig().public.directus.rest.nuxtBaseUrl
  const $directus = useDirectusSdk()
  const settings = useShopSettings()

  await $directus.request(
    createItem('customer_message', {
      from: 'system',
      is_unread: false,
      customer: customer?.id,
      intervention: intervention.id,
      message: [
        `Encore merci d'avoir choisi ${
          settings.data?.name ?? 'notre boutique'
        }.`,
        '',
        `Vous pouvez télécharger votre facture en ligne ici: ${joinURL(
          nuxtBaseUrl,
          '/invoice/',
          String(intervention.id),
          String(invoice.id),
          '/print',
        )}`,
        ...(settings.data?.feedback_url
          ? [
              ``,
              `Pouvons-nous vous demander de noter la qualité de notre service ? C'est juste ici : ${settings.data?.feedback_url}`,
              `Votre gratitude nous aide beaucoup !`,
            ]
          : []),
        ``,
        ...(settings.data?.name
          ? [`Votre réparateur,`, `${settings.data?.name}`]
          : [`Votre réparateur`]),
      ].join('\n'),
    }),
  )

  // update customer messages status
  if (customer?.messages_status !== 'archived') {
    await $directus.request(
      updateItem('customer', customer.id, {
        messages_status: 'archived',
      }),
    )
  }
}

export async function sendCreatedNotification({
  intervention,
  formValues,
}: {
  intervention: Intervention
  formValues: FormInput
}) {
  const customer = formValues.customer

  if (!customer?.id) {
    return
  }

  const $directus = useDirectusSdk()
  const settings = useShopSettings()

  let deviceName = 'appareil'

  // get device name
  const { getById } = useDeviceCategory()

  if (formValues.device_model && typeof formValues.device_model === 'object') {
    if (
      formValues.device_model.device_category
      && typeof formValues.device_model.device_category === 'string'
      && getById(formValues.device_model.device_category)
    ) {
      const category = getById(formValues.device_model.device_category)
      deviceName = `${category!.name} ${formValues.device_model.name}`
    }
    else {
      deviceName = formValues.device_model.name ?? 'appareil'
    }
  }

  if (formValues.status === 'require_restocking') {
    await $directus.request(
      createItem('customer_message', {
        from: 'system',
        is_unread: false,
        is_initial_message: true,
        customer: customer?.id,
        intervention: intervention?.id,
        message: [
          `Chèr.e ${capitalize(
            formValues.customer?.first_name ?? 'client.e',
          )}, la réparation de votre ${deviceName} nécessite la commande d'une pièce détachée.`,
          '',
          `Nous poursuivrons l'intervention dès réception de la marchandise (généralement dans les 2 jours ouvrés).`,
          `On vous tient informé.e de la suite très vite !`,
          '',
          `N'hésitez pas à répondre à ce message pour toutes questions.`,
          '',
          ...(settings.data?.name
            ? [`Votre réparateur,`, `${settings.data?.name}`]
            : [`Votre réparateur`]),
        ].join('\n'),
      }),
    )
  }
  else {
    // send 'new' notification
    await $directus.request(
      createItem('customer_message', {
        from: 'system',
        is_unread: false,
        is_initial_message: true,
        customer: customer?.id,
        intervention: intervention?.id,
        message: [
          `Bonjour ${capitalize(formValues.customer?.first_name ?? '')},`,
          '',
          `merci d'avoir déposé votre ${deviceName} auprès de ${settings.data?.name ?? 'notre boutique'}.`,
          `Vous serez notifié.e dès que sa réparation débutera. À plus tard !`,
          '',
          `N'hésitez pas à répondre à ce message pour toutes questions.`,
          '',
          ...(settings.data?.name
            ? [`Votre réparateur,`, `${settings.data?.name}`]
            : [`Votre réparateur`]),
        ].join('\n'),
      }),
    )
  }

  // update customer messages status
  if (customer?.messages_status !== 'active') {
    await $directus.request(
      updateItem('customer', customer.id, {
        messages_status: 'active',
      }),
    )
  }
}
