import type {
  Customer,
  CustomerDevice,
  DeviceModel,
  InterventionPlannedServiceSparePart,
  Item,
  Service,
} from '@laast-io/types'

import { differenceInMinutes } from 'date-fns'

import type { FetchInterventionPayment } from '~/composables/data/modals/intervention-payment'
import type { FetchIntervention } from '~/composables/data/panels/interventions'
import { calculateServiceSparePartsCost } from './costs'
import { addPrices } from './operators'
import {
  calculateSubtotalDiscount,
  calculateSubtotalPrice,
  calculateTaxesPrice,
  calculateTotalCost,
  calculateTotalGain,
  calculateTotalPrice,
  type PriceData,
} from './price-data'

export function computeInvoiceData(
  intervention?: FetchIntervention | FetchInterventionPayment | null,
  deviceCategory?: string,
) {
  const settings = useShopSettings()
  const customer = intervention?.customer as Customer | undefined
  const deviceModel = intervention?.device_model as DeviceModel | undefined
  const customerDevice = intervention?.customer_device as
    | CustomerDevice
    | undefined

  const data = {
    intervention: intervention?.id,
    customer: customer?.id,
    comment: intervention?.comment_invoice,
    details: {
      items: [] as Record<string, any>[],
      services: [] as Record<string, any>[],
      customer: {} as Record<string, any>,
      device_model: {} as Record<string, any>,
    },
    duration: 0,
    item_quantity: 0,
    // prices exclude tax
    item_subtotal: 0,
    service_subtotal: 0,
    subtotal: 0,
    // prices include tax
    item_total: 0,
    service_total: 0,
    total: 0,
    // taxes
    item_tax_total: 0,
    item_tax_margin_total: 0,
    service_tax_total: 0,
    tax_total: 0,
    // cost
    item_cost_total: 0,
    service_cost_total: 0,
    cost_total: 0,
    // gain
    item_gain_total: 0,
    service_gain_total: 0,
    gain_total: 0,
    // discount
    item_discount_total: 0,
    service_discount_total: 0,
    discount_total: 0,
    // refunds
    retund_total: 0,
  } satisfies Record<string, any>

  // customer
  if (customer) {
    data.details.customer.name = formatFullname(customer)
    data.details.customer.company = customer?.company
    data.details.customer.phone = customer?.phone
    data.details.customer.email = customer?.email

    if (typeof customer?.addresses?.[0] === 'object') {
      const address = customer?.addresses?.[0]
      data.details.customer.line_1 = address.line_1
      data.details.customer.line_2 = address.line_2
      data.details.customer.city = address.city
      data.details.customer.zip_code = address.zip_code
    }
  }

  // device model
  if (deviceModel) {
    data.details.device_model.id = deviceModel.id
    data.details.device_model.name = deviceModel.name
    data.details.device_model.category = deviceCategory
    data.details.device_model.serial_number = customerDevice?.serial_number
  }

  // duration
  if (intervention?.date_started && intervention?.date_completed) {
    data.duration = Math.max(
      0,
      differenceInMinutes(
        new Date(intervention?.date_completed),
        new Date(intervention?.date_started),
      ),
    )
  }

  // cart items
  if (intervention?.items && Array.isArray(intervention?.items)) {
    for (const item of intervention.items) {
      const article = item.item as Item | undefined
      if (typeof item !== 'object' || typeof article !== 'object' || !article) {
        continue
      }

      const itemData: PriceData = {
        unitary_price: article.unitary_price,
        unitary_cost: article.unitary_cost,
        tax_ratio: article.tax_ratio ?? settings.data?.default_tax_ratio ?? 0.2,
        discount: item.discount,
        discount_type: item.discount_type,
        quantity: item.quantity,
        is_second_hand: article.is_second_hand,
      }

      const itemSubtotal = calculateSubtotalPrice(itemData)
      const itemTotal = calculateTotalPrice(itemData)
      const itemTax = calculateTaxesPrice(itemData)
      const itemCost = calculateTotalCost(itemData)
      const itemGain = calculateTotalGain(itemData)
      const itemDiscount = calculateSubtotalDiscount(itemData)

      const itemUnitPriceExclTaxes = calculateSubtotalPrice({
        unitary_price: article.unitary_price,
        tax_ratio: article.tax_ratio ?? settings.data?.default_tax_ratio ?? 0.2,
      })
      const itemSubtotalExclDiscount = calculateSubtotalPrice({
        unitary_price: article.unitary_price,
        tax_ratio: article.tax_ratio ?? settings.data?.default_tax_ratio ?? 0.2,
        quantity: item.quantity,
        is_second_hand: article.is_second_hand,
      })
      const itemTotalExclDiscount = calculateTotalPrice({
        unitary_price: article.unitary_price,
        tax_ratio: article.tax_ratio ?? settings.data?.default_tax_ratio ?? 0.2,
        quantity: item.quantity,
        is_second_hand: article.is_second_hand,
      })

      data.details.items.push({
        id: article.id,
        name: article.name,
        reference: article.reference,
        quantity: item.quantity,
        unitary_price_excl_tax: itemUnitPriceExclTaxes,
        unitary_price: article.unitary_price,
        unitary_cost: article.unitary_cost,
        discount: item.discount,
        discount_id: item.discount_id,
        discount_label: item.discount_label,
        discount_type: item.discount_type,
        tax_ratio: article.tax_ratio ?? settings.data?.default_tax_ratio ?? 0.2,
        is_second_hand: article.is_second_hand,
        total: itemTotal,
        total_excl_discount: itemTotalExclDiscount,
        subtotal: itemSubtotal,
        subtotal_excl_discount: itemSubtotalExclDiscount,
        cost_total: itemCost,
        gain_total: itemGain,
        tax_total: article.is_second_hand ? 0 : itemTax,
        tax_margin_total: article.is_second_hand ? itemTax : 0,
        discount_total: itemDiscount,
        refund_total: 0,
        refund_restocked: 0,
      })

      data.item_quantity += item.quantity

      // prices exclude tax
      data.item_subtotal = addPrices(data.item_subtotal, itemSubtotal)
      data.subtotal = addPrices(data.subtotal, itemSubtotal)
      // prices include tax
      data.item_total = addPrices(data.item_total, itemTotal)
      data.total = addPrices(data.total, itemTotal)
      // taxes
      data.item_tax_total = addPrices(
        data.item_tax_total,
        article.is_second_hand ? 0 : itemTax,
      )
      data.item_tax_margin_total = addPrices(
        data.item_tax_margin_total,
        article.is_second_hand ? itemTax : 0,
      )
      data.tax_total = addPrices(data.tax_total, itemTax)
      // cost
      data.item_cost_total = addPrices(data.item_cost_total, itemCost)
      data.cost_total = addPrices(data.cost_total, itemCost)
      // gain
      data.item_gain_total = addPrices(data.item_gain_total, itemGain)
      data.gain_total = addPrices(data.gain_total, itemGain)
      // discount
      data.item_discount_total = addPrices(
        data.item_discount_total,
        itemDiscount,
      )
      data.discount_total = addPrices(data.discount_total, itemDiscount)
    }
  }

  // planned services
  if (
    intervention?.planned_services
    && Array.isArray(intervention?.planned_services)
  ) {
    for (const planned of intervention?.planned_services ?? []) {
      const service = planned.service as Service | null
      const requireSparePart = planned.require_spare_part as
        | InterventionPlannedServiceSparePart[]
        | undefined
      if (
        typeof planned !== 'object'
        || typeof service !== 'object'
        || !service
      ) {
        continue
      }

      let priceModifiers = 0
      if (Array.isArray(requireSparePart)) {
        for (const item of requireSparePart) {
          if (item.price_modifier) {
            priceModifiers += item.price_modifier
          }
        }
      }

      const serviceSparePartsCost = calculateServiceSparePartsCost(
        requireSparePart?.map((part: any) => ({
          ...part,
          quantity: part.quantity,
        })) ?? [],
      )

      const serviceData: PriceData = {
        unitary_price: addPrices(service?.unitary_price, priceModifiers),
        unitary_cost: serviceSparePartsCost,
        tax_ratio: service?.tax_ratio ?? settings.data?.default_tax_ratio,
        discount: planned.discount,
        discount_type: planned.discount_type,
        quantity: 1,
      }

      const serviceSubtotal = calculateSubtotalPrice(serviceData)
      const serviceTotal = calculateTotalPrice(serviceData)
      const serviceTax = calculateTaxesPrice(serviceData)
      const serviceCost = calculateTotalCost(serviceData)
      const serviceGain = calculateTotalGain(serviceData)
      const serviceDiscount = calculateSubtotalDiscount(serviceData)

      const serviceUnitPriceExclTaxes = calculateSubtotalPrice({
        unitary_price: addPrices(service?.unitary_price, priceModifiers),
        tax_ratio: service?.tax_ratio ?? settings.data?.default_tax_ratio,
      })
      const serviceSubtotalExclDiscount = calculateSubtotalPrice({
        unitary_price: addPrices(service?.unitary_price, priceModifiers),
        tax_ratio: service?.tax_ratio ?? settings.data?.default_tax_ratio,
        quantity: 1,
      })
      const serviceTotalExclDiscount = calculateTotalPrice({
        unitary_price: addPrices(service?.unitary_price, priceModifiers),
        tax_ratio: service?.tax_ratio ?? settings.data?.default_tax_ratio,
        quantity: 1,
      })

      data.details.services.push({
        id: service?.id,
        name: service?.name,
        quantity: 1,
        unitary_price_excl_tax: serviceUnitPriceExclTaxes,
        unitary_price: addPrices(service?.unitary_price, priceModifiers),
        unitary_cost: serviceSparePartsCost,
        discount: planned.discount,
        discount_type: planned.discount_type,
        discount_id: planned.discount_id,
        discount_label: planned.discount_label,
        tax_ratio: service?.tax_ratio,
        total: serviceTotal,
        total_excl_discount: serviceTotalExclDiscount,
        subtotal: serviceSubtotal,
        subtotal_excl_discount: serviceSubtotalExclDiscount,
        cost_total: serviceCost,
        gain_total: serviceGain,
        tax_total: serviceTax,
        discount_total: serviceDiscount,
        refund_total: 0,
        included_spare_parts: requireSparePart
          ?.map((part: any) => {
            return {
              id: part.spare_part.id,
              name: part.spare_part.name,
              reference: part.spare_part.reference,
              quantity: part.quantity,
              refund_restocked: 0,
            }
          })
          .filter(Boolean),
      })

      // prices exclude tax
      data.service_subtotal = addPrices(data.service_subtotal, serviceSubtotal)
      data.subtotal = addPrices(data.subtotal, serviceSubtotal)
      // prices include tax
      data.service_total = addPrices(data.service_total, serviceTotal)
      data.total = addPrices(data.total, serviceTotal)
      // taxes
      data.service_tax_total = addPrices(data.service_tax_total, serviceTax)
      data.tax_total = addPrices(data.tax_total, serviceTax)
      // cost
      data.service_cost_total = addPrices(data.service_cost_total, serviceCost)
      data.cost_total = addPrices(data.cost_total, serviceCost)
      // gain
      data.service_gain_total = addPrices(data.service_gain_total, serviceGain)
      data.gain_total = addPrices(data.gain_total, serviceGain)
      // discount
      data.service_discount_total = addPrices(
        data.service_discount_total,
        serviceDiscount,
      )
      data.discount_total = addPrices(data.discount_total, serviceDiscount)
    }
  }

  return data
}
